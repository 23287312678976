<template>
  <div>
    <div class="container mt-5">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/home" tag="a">{{ $t('menu.home') }}</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">{{ $t('menu.information') }}</li>
        </ol>
      </nav>
      <div class="row">
        <div class="col-12 pt-3">
          <h2 class="title pl-1">{{ $t('menu.chargee') }}</h2>
        </div>
      </div>
      <section id="about" class="about">
        <div class="container">
          <div class="row content">
            <div class="col-lg-12 pt-3 pb-4">
              <p style="font-size: medium;"><strong>{{$t('access.responsable.name')}}</strong></p>
              <p>{{$t('access.responsable.dep')}}</p>
              <p><em>{{$t('access.responsable.post')}}</em></p>
              <p><i class="fa fa-phone pl-1"></i> : &#x200E; 71 727 803 </p>
              <p><i class="fa fa-envelope pl-1"></i> : 
              dhaoui.mhamed@ites.tn</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
//Vuejs slides
import apiAxios from '@/services/axios'

export default {
  name: 'Access',
  data: () => ({}),
  methods: {},
}
</script>
<style lang="scss" scoped>
@import '@/mixins.scss';
.title {
  border-bottom: 2px solid $primary;
  width: 20em;
}

/* Pagination style */
@mixin transition-hover($x) {
  transition: all $x linear;
}

.pagination {
  text-align: center;
  padding: 20px 0;
  display: flex;
  justify-content: center;

  a {
    font-size: 1.08em;
    width: 50px;
    height: 50px;
    background-color: transparent;
    color: #222;
    border: 2px solid rgb(139, 139, 139);
    display: inline-block;
    vertical-align: middle;
    line-height: 45px;
    text-decoration: none;
    font-weight: 700;
    margin: 4px;

    @include transition-hover(0.2s);

    &:hover,
    &.selected {
      color: #fff;
      background-color: $primary;
      border-color: $primary;
    }
  }
}

form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

label {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
  /* Accessible outline */
  /* Remove comment to use */
  /*
		&:focus-within {
				outline: .125em solid $primary-color;
		}
	*/
  input {
    position: absolute;
    left: -9999px;
    &:checked + span {
      background-color: mix(#fff, $primary, 84%);
      &:before {
        box-shadow: inset 0 0 0 0.4375em $primary;
      }
    }
  }
  span {
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em; // or something higher...
    transition: 0.25s ease;
    &:hover {
      background-color: mix(#fff, $primary, 84%);
    }
    &:before {
      display: flex;
      flex-shrink: 0;
      content: '';
      background-color: #fff;
      width: 1.5em;
      height: 1.5em;
      border-radius: 50%;
      margin-right: 0.375em;
      transition: 0.25s ease;
      box-shadow: inset 0 0 0 0.125em $primary;
    }
  }
}

// End Codepen spesific styling
</style>
